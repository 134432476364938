import React, { FC, ReactNode, useState } from "react";
import {
    AppBar,
    Box,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Toolbar,
    Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { StringMap } from "mobx-state-router/dist/types/router-store";
import { useRootStore } from "../../../utilities";

type HeaderWrapperType = FC<{ UserWidget?: ReactNode; navigation?: DrawerNavigationParams[] }>;
type SetDrawerStateCallback = (x: boolean) => void;
export type DrawerNavigationParams = {
    pathName: string;
    text: string;
    icon?: ReactNode;
    params?: StringMap;
    queryParams?: {};
};
type CustomDrawerType = FC<{
    opened: boolean;
    setOpened: SetDrawerStateCallback;
    navigation: DrawerNavigationParams[];
}>;

type NavigateCurryCallback = (x: Pick<DrawerNavigationParams, "pathName" | "params" | "queryParams">) => () => void;

const CustomDrawer: CustomDrawerType = ({ opened, setOpened, navigation }) => {
    const { routerStore } = useRootStore().rootStore;

    const navigateCallback: NavigateCurryCallback = ({ pathName, queryParams, params }) => () => {
        routerStore.goTo(pathName, params, queryParams);
        setOpened(false);
    };

    const items = navigation.map(({ text, pathName, queryParams, params, icon }) => (
        <ListItem disablePadding key={`navigation-${pathName}`}>
            <ListItemButton onClick={navigateCallback({ params, queryParams, pathName })}>
                {icon}
                <ListItemText primary={text} />
            </ListItemButton>
        </ListItem>
    ));

    return (
        <Drawer open={opened} onClose={() => setOpened(false)} anchor={"left"}>
            <Box sx={{ width: 250 }} role="presentation">
                <List>{items}</List>
            </Box>
        </Drawer>
    );
};

export const HeaderWrapper: HeaderWrapperType = ({ children, UserWidget, navigation = [] }) => {
    const [opened, setOpened] = useState(false);
    const menuIconVisible = navigation?.length != 0;
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    {menuIconVisible && (
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                            onClick={() => setOpened(true)}
                        >
                            <MenuIcon></MenuIcon>
                        </IconButton>
                    )}
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Shiva
                    </Typography>
                    {UserWidget}
                </Toolbar>
            </AppBar>
            <CustomDrawer opened={opened} setOpened={setOpened} navigation={navigation} />
            {children}
        </Box>
    );
};
