import React from "react";
import { convertRoutes, RouteTransitionHook } from "src/routing/route";
import { Route, RouterState } from "mobx-state-router";
import {
    UserContainerCreate,
    UserContainerList,
    UserContainerView,
    UserMainPage,
    UserProfilePage,
} from "src/pages/user/UserLazyRoutes";
import { AnonRouteNames } from "src/pages/anon/AnonRoutes";

const UserAuthorizedOnlyHook: RouteTransitionHook = (root) => {
    if (!root.userRpc.isAuthorized) throw new RouterState(AnonRouteNames.userLogin);
};

const EnsureProfileLoadedHook: RouteTransitionHook = async (root) => {
    if (!root.userProfileStore.profile) await root.userProfileStore.loadAll();
};

const UserAllowOnlyFilledProfilesHook: RouteTransitionHook = async (root) => {
    const profile = root.userProfileStore.profile;
    const profileFilled = profile && profile.name;
    if (!profileFilled) throw new RouterState(UserRouteNames.profile);
};

export enum UserRouteNames {
    mainPage = "user-main-page",
    profile = "user-profile-page",

    containerView = "user-container-view",
    containerCreate = "user-container-create",
    containerList = "user-container-list",
}

export const UserViewMap = {
    [UserRouteNames.mainPage]: <UserMainPage />,
    [UserRouteNames.profile]: <UserProfilePage />,
    [UserRouteNames.containerCreate]: <UserContainerCreate />,
    [UserRouteNames.containerView]: <UserContainerView />,
    [UserRouteNames.containerList]: <UserContainerList />,
};

export const UserRoutes: Route[] = convertRoutes([
    {
        pattern: "/",
        name: UserRouteNames.mainPage,
        hooks: [UserAuthorizedOnlyHook, EnsureProfileLoadedHook],
        onEnter: (root) => {},
    },
    {
        pattern: "/containers",
        name: UserRouteNames.containerList,
        hooks: [UserAuthorizedOnlyHook, EnsureProfileLoadedHook],
        onEnter: (root) => root.containerListStore.refresh(),
    },
    {
        pattern: "/container/:id/view",
        name: UserRouteNames.containerView,
        hooks: [UserAuthorizedOnlyHook, EnsureProfileLoadedHook, UserAllowOnlyFilledProfilesHook],
        onEnter: (root, to) => root.containerViewStore.load(to.params.id),
    },
    {
        pattern: "/container/create",
        name: UserRouteNames.containerCreate,
        hooks: [UserAuthorizedOnlyHook, EnsureProfileLoadedHook, UserAllowOnlyFilledProfilesHook],
        onEnter: (root, to) => root.containerCreateStore.reset(),
    },
    {
        pattern: "/profile",
        name: UserRouteNames.profile,
        hooks: [UserAuthorizedOnlyHook, EnsureProfileLoadedHook, UserAllowOnlyFilledProfilesHook],
        onEnter: (root) => root.userProfileStore.loadAll(),
    },
]);
