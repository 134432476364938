import { RootStore } from "src/stores/RootStore";
import { AutoCompleteSelectStore } from "src/stores/interfaces/AutoCompleteSelectStore";
import { action, observable } from "mobx";

export class UserMainStore implements AutoCompleteSelectStore<{ title: string }> {
    constructor(private readonly root: RootStore) {}

    @observable items: { title: string }[] = [];
    @observable labelField: string = "title";
    @observable valueField: string = "title";

    @action async suggest(query: string): Promise<void> {
        this.items = [{ title: "Hello" }, { title: "World" }].filter((item) => item.title.includes(query));
    }

    @action async load(): Promise<void> {
        // Do nothing yet.
    }
}
