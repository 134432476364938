export interface ErrorCode {
        code : string;
        description : string;
}

export interface ResultT<T> {
        value : T;
        success : boolean;
        error : ErrorCode;
}

export interface UserProfileDto {
        id : string;
        email : string;
        name : string;
        avatarImage : string | null;
        containerQuota : number;
}

export interface Result {
        success : boolean;
        error : ErrorCode;
}

export enum DesiredState {
        Running = "Running",
        Stopped = "Stopped",
        Deleted = "Deleted",
        Error = "Error",
}

export enum ContainerState {
        Creating = "Creating",
        Starting = "Starting",
        Working = "Working",
        Stopping = "Stopping",
        Stopped = "Stopped",
        Deleting = "Deleting",
        Deleted = "Deleted",
        Error = "Error",
}

export interface ContainerDto {
        id : string;
        name : string;
        ownerId : string;
        hardwareServerId : string;
        sshKey : string;
        baseImageId : string;
        configurationId : string;
        ipAddress : string;
        desiredState : DesiredState;
        state : ContainerState;
}

export interface HardwareServerDto {
        id : string;
        name : string;
        address : string;
        network : string;
}

export interface ContainerConfigurationDto {
        id : string;
        name : string;
        virtualCpuCount : number;
        memorySizeMb : number;
}

export interface ContainerBaseImageDto {
        id : string;
        name : string;
        rootFsPath : string;
}

export interface ContainerInfoDto {
        container : ContainerDto;
        hardwareServer : HardwareServerDto;
        configuration : ContainerConfigurationDto;
        baseImage : ContainerBaseImageDto;
}

export interface ListResult<T> {
        items : T[];
        totalCount : number;
}

export interface ContainerCreateDto {
        templateId : string;
        name : string;
        sshKey : string;
        baseImageId : string;
        hardwareServerId : string;
        configurationId : string;
}

export interface UserRoleDto {
        userId : string;
        role : string;
}


export interface ICoreRpcProxyForUserLoginRpc {
    register (email : string, password : string) : Promise<ResultT<UserProfileDto>>;
    resendConfirmationCode (email : string, password : string) : Promise<Result>;
    confirmAccount (code : string) : Promise<ResultT<string>>;
    resetPassword (email : string) : Promise<Result>;
    changePassword (code : string, password : string) : Promise<ResultT<string>>;
    login (username : string, password : string) : Promise<ResultT<string>>;
    loginAdmin (username : string, password : string) : Promise<ResultT<string>>;
    checkAuthToken () : Promise<boolean>;
}
export class CoreRpcProxyForUserLoginRpc implements ICoreRpcProxyForUserLoginRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public register (email : string, password : string) : Promise<ResultT<UserProfileDto>>    {
        return this.parent.send<ResultT<UserProfileDto>>({Target: 'UserLoginRpc', MethodSignature: 'CFJlZ2lzdGVyDVN5c3RlbS5TdHJpbmcNU3lzdGVtLlN0cmluZw==', Arguments: [email,password] });
    }
    public resendConfirmationCode (email : string, password : string) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'UserLoginRpc', MethodSignature: 'FlJlc2VuZENvbmZpcm1hdGlvbkNvZGUNU3lzdGVtLlN0cmluZw1TeXN0ZW0uU3RyaW5n', Arguments: [email,password] });
    }
    public confirmAccount (code : string) : Promise<ResultT<string>>    {
        return this.parent.send<ResultT<string>>({Target: 'UserLoginRpc', MethodSignature: 'DkNvbmZpcm1BY2NvdW50DVN5c3RlbS5TdHJpbmc=', Arguments: [code] });
    }
    public resetPassword (email : string) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'UserLoginRpc', MethodSignature: 'DVJlc2V0UGFzc3dvcmQNU3lzdGVtLlN0cmluZw==', Arguments: [email] });
    }
    public changePassword (code : string, password : string) : Promise<ResultT<string>>    {
        return this.parent.send<ResultT<string>>({Target: 'UserLoginRpc', MethodSignature: 'DkNoYW5nZVBhc3N3b3JkDVN5c3RlbS5TdHJpbmcNU3lzdGVtLlN0cmluZw==', Arguments: [code,password] });
    }
    public login (username : string, password : string) : Promise<ResultT<string>>    {
        return this.parent.send<ResultT<string>>({Target: 'UserLoginRpc', MethodSignature: 'BUxvZ2luDVN5c3RlbS5TdHJpbmcNU3lzdGVtLlN0cmluZw==', Arguments: [username,password] });
    }
    public loginAdmin (username : string, password : string) : Promise<ResultT<string>>    {
        return this.parent.send<ResultT<string>>({Target: 'UserLoginRpc', MethodSignature: 'CkxvZ2luQWRtaW4NU3lzdGVtLlN0cmluZw1TeXN0ZW0uU3RyaW5n', Arguments: [username,password] });
    }
    public checkAuthToken () : Promise<boolean>    {
        return this.parent.send<boolean>({Target: 'UserLoginRpc', MethodSignature: 'DkNoZWNrQXV0aFRva2Vu', Arguments: [] });
    }
}
export interface ICoreRpcProxyForUserProfileRpc {
    getProfile () : Promise<UserProfileDto>;
    updateProfile (profile : UserProfileDto) : Promise<Result>;
    uploadUserPhoto (data : number[]) : Promise<Result>;
    removeUserPhoto () : Promise<Result>;
}
export class CoreRpcProxyForUserProfileRpc implements ICoreRpcProxyForUserProfileRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public getProfile () : Promise<UserProfileDto>    {
        return this.parent.send<UserProfileDto>({Target: 'UserProfileRpc', MethodSignature: 'CkdldFByb2ZpbGU=', Arguments: [] });
    }
    public updateProfile (profile : UserProfileDto) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'UserProfileRpc', MethodSignature: 'DVVwZGF0ZVByb2ZpbGUYU2hpdmEuRHRvLlVzZXJQcm9maWxlRHRv', Arguments: [profile] });
    }
    public uploadUserPhoto (data : number[]) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'UserProfileRpc', MethodSignature: 'D1VwbG9hZFVzZXJQaG90b4sBU3lzdGVtLkNvbGxlY3Rpb25zLkdlbmVyaWMuTGlzdGAxW1tTeXN0ZW0uQnl0ZSwgU3lzdGVtLlByaXZhdGUuQ29yZUxpYiwgVmVyc2lvbj02LjAuMC4wLCBDdWx0dXJlPW5ldXRyYWwsIFB1YmxpY0tleVRva2VuPTdjZWM4NWQ3YmVhNzc5OGVdXQ==', Arguments: [data] });
    }
    public removeUserPhoto () : Promise<Result>    {
        return this.parent.send<Result>({Target: 'UserProfileRpc', MethodSignature: 'D1JlbW92ZVVzZXJQaG90bw==', Arguments: [] });
    }
}
export interface ICoreRpcProxyForContainerRpc {
    dtoSchema () : Promise<any>;
    get (id : string) : Promise<ResultT<ContainerInfoDto>>;
    listByUserId (id : string, skip : number, take : number) : Promise<ListResult<ContainerDto>>;
    create (model : ContainerCreateDto) : Promise<ResultT<string>>;
    getContainerConnectionString (id : string) : Promise<ResultT<string>>;
    start (id : string) : Promise<Result>;
    delete (id : string) : Promise<Result>;
    stop (id : string) : Promise<Result>;
}
export class CoreRpcProxyForContainerRpc implements ICoreRpcProxyForContainerRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public dtoSchema () : Promise<any>    {
        return this.parent.send<any>({Target: 'ContainerRpc', MethodSignature: 'CUR0b1NjaGVtYQ==', Arguments: [] });
    }
    public get (id : string) : Promise<ResultT<ContainerInfoDto>>    {
        return this.parent.send<ResultT<ContainerInfoDto>>({Target: 'ContainerRpc', MethodSignature: 'A0dldAtTeXN0ZW0uR3VpZA==', Arguments: [id] });
    }
    public listByUserId (id : string, skip : number, take : number) : Promise<ListResult<ContainerDto>>    {
        return this.parent.send<ListResult<ContainerDto>>({Target: 'ContainerRpc', MethodSignature: 'DExpc3RCeVVzZXJJZAtTeXN0ZW0uR3VpZAxTeXN0ZW0uSW50MzIMU3lzdGVtLkludDMy', Arguments: [id,skip,take] });
    }
    public create (model : ContainerCreateDto) : Promise<ResultT<string>>    {
        return this.parent.send<ResultT<string>>({Target: 'ContainerRpc', MethodSignature: 'BkNyZWF0ZRxTaGl2YS5EdG8uQ29udGFpbmVyQ3JlYXRlRHRv', Arguments: [model] });
    }
    public getContainerConnectionString (id : string) : Promise<ResultT<string>>    {
        return this.parent.send<ResultT<string>>({Target: 'ContainerRpc', MethodSignature: 'HEdldENvbnRhaW5lckNvbm5lY3Rpb25TdHJpbmcLU3lzdGVtLkd1aWQ=', Arguments: [id] });
    }
    public start (id : string) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'ContainerRpc', MethodSignature: 'BVN0YXJ0C1N5c3RlbS5HdWlk', Arguments: [id] });
    }
    public delete (id : string) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'ContainerRpc', MethodSignature: 'BkRlbGV0ZQtTeXN0ZW0uR3VpZA==', Arguments: [id] });
    }
    public stop (id : string) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'ContainerRpc', MethodSignature: 'BFN0b3ALU3lzdGVtLkd1aWQ=', Arguments: [id] });
    }
}
export interface ICoreRpcProxyForRolesRpc {
    getMyRoles () : Promise<string[]>;
    getUserRolesByIds (ids : string[]) : Promise<UserRoleDto[]>;
}
export class CoreRpcProxyForRolesRpc implements ICoreRpcProxyForRolesRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public getMyRoles () : Promise<string[]>    {
        return this.parent.send<string[]>({Target: 'RolesRpc', MethodSignature: 'CkdldE15Um9sZXM=', Arguments: [] });
    }
    public getUserRolesByIds (ids : string[]) : Promise<UserRoleDto[]>    {
        return this.parent.send<UserRoleDto[]>({Target: 'RolesRpc', MethodSignature: 'EUdldFVzZXJSb2xlc0J5SWRziwFTeXN0ZW0uQ29sbGVjdGlvbnMuR2VuZXJpYy5MaXN0YDFbW1N5c3RlbS5HdWlkLCBTeXN0ZW0uUHJpdmF0ZS5Db3JlTGliLCBWZXJzaW9uPTYuMC4wLjAsIEN1bHR1cmU9bmV1dHJhbCwgUHVibGljS2V5VG9rZW49N2NlYzg1ZDdiZWE3Nzk4ZV1d', Arguments: [ids] });
    }
}
export interface ICoreApi {
    userLogin : ICoreRpcProxyForUserLoginRpc;
    userProfile : ICoreRpcProxyForUserProfileRpc;
    container : ICoreRpcProxyForContainerRpc;
    roles : ICoreRpcProxyForRolesRpc;
}
export class CoreApi implements ICoreApi {
    private baseUrl: string;
    private fetch: (url: string, init: RequestInit) => Promise<Response>;
    constructor(baseUrl : string, customFetch?: (url: string, init: RequestInit) => Promise<Response>) {
    this.baseUrl = baseUrl;
    if(customFetch) this.fetch = customFetch; else this.fetch =  (r, i) => fetch(r, i);
    this.userLogin = new CoreRpcProxyForUserLoginRpc(this);
    this.userProfile = new CoreRpcProxyForUserProfileRpc(this);
    this.container = new CoreRpcProxyForContainerRpc(this);
    this.roles = new CoreRpcProxyForRolesRpc(this);
    }
    public send<T>(request: any) : Promise<T>{
    return this.fetch(this.baseUrl, {method: 'post', body: JSON.stringify(request)})
        .then(response => {
            if (!response.ok)
                throw new Error(response.statusText);
             return response.json();
        }).then(jr => {
            const r = <{Result?: T, Exception?: string}>jr;
            if(r.Exception) throw r.Exception;
            return r.Result!;
        });
    }
    userLogin : CoreRpcProxyForUserLoginRpc;
    userProfile : CoreRpcProxyForUserProfileRpc;
    container : CoreRpcProxyForContainerRpc;
    roles : CoreRpcProxyForRolesRpc;
}
