import { RouterView } from "mobx-state-router";
import { useRootStore } from "src/utilities";
import { UserRouteNames, UserViewMap } from "src/pages/user/UserRoutes";
import { useObserver } from "mobx-react";
import { SuspensePlaceholder } from "src/components/SuspensePlaceholder/SuspensePlaceholder";
import { DrawerNavigationParams, HeaderWrapper } from "../NewComponents/Header/Header";
import { FC } from "react";
import { UserProfileStore } from "../../stores/user/UserProfileStore";
import { AnonUserLoginStore } from "../../stores/anon/AnonUserLoginStore";
import { Avatar, Container, IconButton, Typography } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import StorageIcon from "@mui/icons-material/Storage";
import SettingsIcon from "@mui/icons-material/Settings";

const UserWidget: FC<{ store: UserProfileStore; loginStore: AnonUserLoginStore }> = ({ loginStore, store }) => {
    if (!store.profile) return <div />;
    const letter = store.profile.name[0];
    return (
        <div style={{ alignItems: "center", display: "flex" }}>
            <Avatar sx={{ width: 24, height: 24, marginRight: 1 }}
                    src={store.profile.avatarImage ?? ""}>
                {letter}
            </Avatar>
            <Typography>{store.profile.name}</Typography>
            <IconButton onClick={() => loginStore.LogOut()}>
                <LogoutIcon sx={{ color: "white" }} />
            </IconButton>
        </div>
    );
};

const nav: DrawerNavigationParams[] = [
    {
        pathName: UserRouteNames.containerList,
        text: "Контейнеры",
        icon: <StorageIcon color={"primary"} sx={{ marginRight: 1 }} />,
    },
    {
        pathName: UserRouteNames.profile,
        text: "Настройки",
        icon: <SettingsIcon color={"primary"} sx={{ marginRight: 1 }} />,
    },
];

export const UserShell = () => {
    const { rootStore } = useRootStore();
    const { routerStore, userProfileStore, anonUserLoginStore } = rootStore;
    const userWidget = <UserWidget store={userProfileStore} loginStore={anonUserLoginStore} />;
    return useObserver(() => (
        <HeaderWrapper UserWidget={userWidget} navigation={nav}>
            <SuspensePlaceholder>
                <Container maxWidth="lg" style={{ marginTop: "20px" }}>
                    <RouterView routerStore={routerStore} viewMap={UserViewMap} />
                </Container>
            </SuspensePlaceholder>
        </HeaderWrapper>
    ));
};
