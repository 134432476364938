import React from "react";
import { convertRoutes, RouteTransitionHook } from "src/routing/route";
import { Route, RouterState } from "mobx-state-router";
import { RootStore } from "src/stores/RootStore";
import { AnonNotFoundPage, AnonUserRegisterPage, AnonUserLoginPage } from "src/pages/anon/AnonLazyRoutes";
import { UserRouteNames } from "src/pages/user/UserRoutes";

const AnonAuthorizedRedirectHook: (
    getRouteName: (root: RootStore) => string,
    checkAuth: (root: RootStore) => boolean
) => RouteTransitionHook = (getRouteName, checkAuth) => async (root, _, to) => {
    if (!checkAuth(root)) return;
    const routeName = getRouteName(root);
    if (!routeName) return;
    const state = new RouterState(routeName, to.params, to.queryParams);
    const route = root.routerStore.getRoute(routeName);
    if (route.onEnter) await route.onEnter(to, state, root.routerStore);
    throw state;
};

export enum AnonRouteNames {
    notFound = "not-found",
    userLogin = "anon-user-login",
    userRegister = "anon-user-register",
    adminLogin = "anon-admin-login",
}

export const AnonViewMap = {
    [AnonRouteNames.notFound]: <AnonNotFoundPage />,
    [AnonRouteNames.userLogin]: <AnonUserLoginPage />,
    [AnonRouteNames.userRegister]: <AnonUserRegisterPage />,
};

export const AnonRoutes: Route[] = convertRoutes([
    {
        pattern: "/not-found",
        name: AnonRouteNames.notFound,
    },
    {
        pattern: "/register",
        name: AnonRouteNames.userRegister,
        hooks: [
            AnonAuthorizedRedirectHook(
                () => UserRouteNames.mainPage,
                (root) => root.userRpc.isAuthorized
            ),
        ],
    },
    {
        pattern: "/login",
        name: AnonRouteNames.userLogin,
        hooks: [
            AnonAuthorizedRedirectHook(
                () => UserRouteNames.mainPage,
                (root) => root.userRpc.isAuthorized
            ),
        ],
    },
]);
