import { action, computed, observable } from "mobx";
import { RouterState, RouterStore } from "mobx-state-router";
import { UserProfileStore } from "src/stores/user/UserProfileStore";
import { Routes } from "src/routes";
import { AnonUserLoginStore } from "src/stores/anon/AnonUserLoginStore";
import { AnonUserRegisterStore } from "src/stores/anon/AnonUserRegisterStore";
import { AnonUserConfirmAccountStore } from "src/stores/anon/AnonUserConfirmAccountStore";
import { UserMainStore } from "src/stores/user/UserMainStore";
import { CoreApi } from "corerpc-api";
import { ContainerCreateStore, ContainerListStore, ContainerViewStore } from "./user/container-store";

type AuthHeaderKeys = "X-User-Auth";

const apiUrl = `/tsrpc`;

export class SecureCoreApi extends CoreApi {
    @observable private token: string | null;
    private readonly localStorageKey;

    @computed get isAuthorized() {
        return this.token != null;
    }

    constructor(path: string, authHeaderKey: AuthHeaderKeys) {
        super(path, async (url: string, request: RequestInit) => {
            request.credentials = "same-origin";
            request.headers = {};
            if (this.token) request.headers[authHeaderKey] = this.token;
            const res = await fetch(url, request);
            if (res.status == 401) {
                window.location.reload();
                this.resetUserToken();
                await new Promise(() => {
                    // Never
                });
            }
            return res;
        });
        const localStorageKey = "auth-token:" + authHeaderKey;
        this.localStorageKey = localStorageKey;
        this.token = window.localStorage.getItem(localStorageKey);
    }

    @action setUserToken(token: string) {
        this.token = token;
        window.localStorage.setItem(this.localStorageKey, token);
    }

    @action resetUserToken() {
        this.token = null;
        window.localStorage.removeItem(this.localStorageKey);
    }
}

export class RootStore {
    @observable userRpc = new SecureCoreApi(apiUrl, "X-User-Auth");
    @observable anonRpc = new CoreApi(apiUrl);
    @observable routerStore = new RouterStore(this, Routes, new RouterState("not-found"));

    // Anon routes.
    @observable anonUserLoginStore = new AnonUserLoginStore(this);
    @observable anonUserRegisterStore = new AnonUserRegisterStore(this);
    @observable anonUserConfirmAccountStore = new AnonUserConfirmAccountStore(this);

    // User specific routes
    @observable userProfileStore = new UserProfileStore(this);
    @observable userMainStore = new UserMainStore(this);

    // Container
    @observable containerCreateStore = new ContainerCreateStore(this);
    @observable containerViewStore = new ContainerViewStore(this);
    @observable containerListStore = new ContainerListStore(this);
}
