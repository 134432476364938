import * as React from "react";
import { RouterView } from "mobx-state-router";
import { useRootStore } from "src/utilities";
import { useObserver } from "mobx-react";
import { SuspensePlaceholder } from "src/components/SuspensePlaceholder/SuspensePlaceholder";
import { AnonViewMap } from "src/pages/anon/AnonRoutes";
import { HeaderWrapper } from "../NewComponents/Header/Header";
import { Container } from "@mui/material";

export const AnonShell = () => {
    const {
        rootStore: { routerStore },
    } = useRootStore();
    return useObserver(() => (
        <HeaderWrapper>
            <SuspensePlaceholder>
                <Container maxWidth="lg" style={{ marginTop: "20px" }}>
                    <RouterView routerStore={routerStore} viewMap={AnonViewMap} />
                </Container>
            </SuspensePlaceholder>
        </HeaderWrapper>
    ));
};
