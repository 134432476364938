import { RootStore } from "../RootStore";
import { RemoteUiEditorStore } from "@kekekeks/remoteui/src";
import {
    ContainerBaseImageDto,
    ContainerConfigurationDto,
    ContainerCreateDto,
    ContainerDto,
    HardwareServerDto
} from "corerpc-api";
import { UserRouteNames } from "../../pages/user/UserRoutes";
import { ReactTableStore } from "../interfaces/ReactTableStore";
import { observable } from "mobx";

export class ContainerListStore extends ReactTableStore<ContainerDto> {
    constructor(private root: RootStore) {
        super();
    }

    async refresh() {
        const { userProfileStore } = this.root;
        const { container } = this.root.userRpc;
        const res = await container.listByUserId(userProfileStore.profile!.id, this.skip, this.take);
        this.fillItems(res.items, res.totalCount);
    }
}

export class ContainerCreateStore {
    public editorStore?: RemoteUiEditorStore;
    constructor(private root: RootStore) {}

    async reset() {
        const { userRpc, userProfileStore } = this.root;
        const schema = await userRpc.container.dtoSchema();
        this.editorStore = new RemoteUiEditorStore(schema, {
            ownerId: userProfileStore.profile?.id,
        });
    }

    async post() {
        const { userRpc, routerStore } = this.root;
        const { editorStore } = this;
        if (!editorStore) return;

        const result = (await editorStore.getDataAsync()) as ContainerCreateDto;

        const { success, error, value } = await userRpc.container.create(result);
        if (!success) throw new Error(error.description);
        await routerStore.goTo(UserRouteNames.containerView, { id: value });
    }
}

export class ContainerViewStore {
    @observable public item?: ContainerDto;
    @observable public hardware?: HardwareServerDto;
    @observable public configuration?: ContainerConfigurationDto;
    @observable public image?: ContainerBaseImageDto;
    @observable public sshConnectionString = "";

    constructor(private root: RootStore) {}

    async load(containerId: string) {
        const { userRpc } = this.root;

        const { success, value, error } = await userRpc.container.get(containerId);
        if (!success) throw new Error(error.description);
        const connectionRes = await userRpc.container.getContainerConnectionString(containerId);
        if (!connectionRes.success) throw new Error(connectionRes.error.description);

        this.sshConnectionString = connectionRes.value;
        this.item = value.container;
        this.hardware = value.hardwareServer;
        this.configuration = value.configuration;
        this.image = value.baseImage;
    }

    async start() {
        const { userRpc } = this.root;
        if (!this.item) return;
        const { success, error } = await userRpc.container.start(this.item.id);
        if (!success) throw new Error(error.description);
        await this.load(this.item.id);
    }

    async stop() {
        const { userRpc } = this.root;
        if (!this.item) return;
        const { success, error } = await userRpc.container.stop(this.item.id);
        if (!success) throw new Error(error.description);
        await this.load(this.item.id);
    }

    async delete() {
        const { userRpc } = this.root;
        if (!this.item) return;
        const { success, error } = await userRpc.container.delete(this.item.id);
        if (!success) throw new Error(error.description);
        await this.load(this.item.id);
    }
}
